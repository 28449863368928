import React from "react"

class SupportForm extends React.Component {

  setFormObjectHandler(e) {
    let formObject = this.props.formObject
    let key = e.target.name
    let value = e.target.value
    if (['first'].includes(key)) {
      value = e.target.checked ? value : ''
    }
    if (['show_top_image'].includes(key)) {
      value = e.target.checked ? value : 'no'
    }
    if (key === 'profile') {
      if (value === '') {
        formObject.showProfiles = ''
      } else {
        formObject.showProfiles = 'yes'
      }
    }
    if (value === '') {
      formObject[key] = ''
    } else {
      formObject[key] = value
    }

    this.props.setFormObject(formObject)
  }

  toggleCollapse(e) {
    e.preventDefault()
    let target = document.querySelector('[data-collapse-target='+e.target.dataset.target+']')
    if (target.classList.contains('collapsed')) {
      target.classList.remove('collapsed')
    } else {
      target.classList.add('collapsed')
    }
  }

  submit(e) {
    e.preventDefault()
  }

  render() {
    return (
      <form id="form" className="form" onSubmit={this.submit.bind(this)}>
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="main">メイン</a>
        <div className="collapse collapsed" data-collapse-target="main">
          <div className="form-group required">
            <label htmlFor="title">タイトル</label>
            <input type="text" id="title" name="title" autoComplete="off"
                   placeholder="会社員のためのお医者さん! 産業医の役割や実践するリフレッシュ方法を教えて!--前編--"
                   value={this.props.formObject.title}
                   onChange={this.setFormObjectHandler.bind(this)}/>
          </div>
          <div className="form-group required">
            <label htmlFor="date">公開日時</label>
            <input type="text" id="date" name="date" autoComplete="off"
                   placeholder="2020-01-01 23:00:00"
                   value={this.props.formObject.date}
                   onChange={this.setFormObjectHandler.bind(this)}/>
          </div>
          <div className="form-group required">
            <label htmlFor="image">メイン画像</label>
            <div className="input-group">
              <input type="text" id="image" name="image" autoComplete="off"
                     placeholder="../images/001.jpg"
                     value={this.props.formObject.image}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <label>
                <input type="checkbox" id="show_top_image" name="show_top_image" value="yes"
                       checked={this.props.formObject.show_top_image !== 'no'}
                       onChange={this.setFormObjectHandler.bind(this)}/> 記事のトップ画像を表示する
              </label>
              <div className="help-box">
                相対パスで入力してください<br/>
                他の画像が入力されていない場合に、デフォルトの画像として使用されます
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="lead">リード</label>
            <div className="input-group">
            <textarea id="lead" name="lead" rows="5" autoComplete="off"
                      placeholder={`働き方改革の促進に伴い、「テレワーク」や「在宅勤務」という言葉を耳にする機会も増えてきましたよね。でも、実際に体験したことのある人はまだまだ少ないのではないでしょうか? そこで、小岩井乳業のマーケティング部で働くママ社員に、在宅勤務での働き方について話を聞きました。`}
                      value={this.props.formObject.lead}
                      onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                記事の前文<br/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="attributes">属性</label>
            <div className="input-group">
              <input type="text" id="attributes" name="attributes" autoComplete="off"
                     placeholder={`new,first,work,life`}
                     value={this.props.formObject.attributes}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                複数ある場合はカンマ区切りで入力してください<br/>
                新着=new, ライフ=life, ワーク=work, 連載=series<br/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="tags">タグ</label>
            <div className="input-group">
              <input type="text" id="tags" name="tags" autoComplete="off"
                     placeholder={`働き方,ライフ,インタビュー`}
                     value={this.props.formObject.tags}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                複数ある場合はカンマ区切りで入力してください<br/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="reserved_at">予約投稿日時</label>
            <input type="text" id="reserved_at" name="reserved_at" autoComplete="off"
                   placeholder="2020-01-01 23:00:00"
                   value={this.props.formObject.reserved_at}
                   onChange={this.setFormObjectHandler.bind(this)}/>
          </div>
        </div>
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="option">その他の項目</a>
        <div className="collapse" data-collapse-target="option">
          <div className="form-group">
            <label htmlFor="thumbnail">サムネイル画像</label>
            <div className="input-group">
              <input type="text" id="thumbnail" name="thumbnail" autoComplete="off"
                     placeholder="../images/001.jpg"
                     value={this.props.formObject.thumbnail}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                一覧用<br/>
                相対パスで入力してください<br/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="related">関連する読みもの</label>
            <div className="input-group">
              <input type="text" id="related" name="related" autoComplete="off"
                     placeholder={`/article/escrit/02,/article/escrit/escritmama`}
                     value={this.props.formObject.related}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                絶対パスで入力してください<br/>
                複数ある場合はカンマ区切りで入力してください<br/>
              </div>
            </div>
          </div>
        </div>
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="author">執筆者について</a>
        <div className="collapse" data-collapse-target="author">
          <div className="form-group">
            <label htmlFor="author">執筆者</label>
            <div className="input-group">
              <input type="text" id="author" name="author" autoComplete="off"
                     placeholder={`編集部`}
                     value={this.props.formObject.author}
                     onChange={this.setFormObjectHandler.bind(this)}/>
            </div>
          </div>
          <div className="form-group">
            <label>プロフィール</label>
            <div className="input-group">
            <textarea id="profile" name="profile" rows="5" autoComplete="off"
                      placeholder={`大阪府立高校卒業し、和歌山県立医科大学を卒業。大阪市立大学医学部付属病院にて初期臨床研修後、精神科医として大阪の精神科救急病院に勤務し、精神科専門医・精神保健指定医を取得。その後、都内複数のメンタルクリニック・スリープクリニックに勤務しながら、大阪・東京で産業医活動を幅広くう。平成28年、<a href="http://www.cocoro-me.com/" target="_blank">（株）産業医グループこころみ</a>を設立。`}
                      value={this.props.formObject.profile}
                      onChange={this.setFormObjectHandler.bind(this)}/>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="avatar">執筆者の画像</label>
            <div className="input-group">
              <input type="text" id="avatar" name="avatar" autoComplete="off"
                     placeholder="../images/avatar.jpg"
                     value={this.props.formObject.avatar}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                相対パスで入力してください<br/>
              </div>
            </div>
          </div>
        </div>
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="series">連載情報</a>
        <div className="collapse" data-collapse-target="series">
          <div className="form-group">
            <label htmlFor="series_title">連載のタイトル</label>
            <div className="input-group">
              <input type="text" id="series_title" name="series_title" autoComplete="off"
                     placeholder={`【連載】アラサー女子のワーホリ生活 in ドイツ`}
                     value={this.props.formObject.series_title}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <label>
                <input type="checkbox" id="first" name="first" value="yes"
                       checked={this.props.formObject.first === 'yes'}
                       onChange={this.setFormObjectHandler.bind(this)}/> 第一回
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="series_path">連載一覧のパス</label>
            <div className="input-group">
              <input type="text" id="series_path" name="series_path" autoComplete="off"
                     placeholder={`/series/workingholiday`}
                     value={this.props.formObject.series_path}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                絶対パスで入力してください<br/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="series_date">連載一覧の日時</label>
            <div className="input-group">
              <input type="text" id="series_date" name="series_date" autoComplete="off"
                     placeholder="2020-01-01 23:00:00"
                     value={this.props.formObject.series_date}
                     onChange={this.setFormObjectHandler.bind(this)}/>
              <div className="help-box">
                未入力の場合は公開日時が使用されます<br/>
              </div>
            </div>
          </div>
        </div>
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="markdown">本文</a>
        <div className="collapse" data-collapse-target="markdown">
          <div className="form-group">
            <div className="input-group">
            <textarea id="markdown" name="markdown" rows="25" autoComplete="off"
                      placeholder={`***

連載第1回目となる今回は、自己紹介も兼ねて「私が仕事を辞めてワーキングホリデーに行くことにした理由」について、ご紹介します。

![](images/s01.jpg "画像はドイツまったく関係ない「マーライオンと私」です。")

## 安定した生活と、海外生活の夢

私は、幼い頃から海外に興味をもっており、高校進学後は３カ月のニュージーランド留学も経験しました。その後も、海外生活への関心や憧れは衰えず、いつか海外で働くことを夢に見ていました。

その夢をかなえるべく、大学の就職活動でも海外の就職先を探してはみたものの、実際に海外就職できるほどの語学力もなく、活かせる資格ももっていなかったため、なかなかうまくいかず、日本で社会人経験を積むことになりました。およそ4年間、日本で会社員として働きましたが、やはり「海外で生活する」という夢を諦めきれず、2018年11月に「やっぱりワーホリ行こう!」と決め、上司に伝えました。

![](images/s02.jpg "学生時代にドイツを旅したときの写真")

もちろん、日本での安定した生活を手放すことは勇気がいりましたが、「仕事を辞めるリスク」と「海外に行きたい気持ち」を天秤にかけたときに、今、ワーホリに行かない選択をすることの方が人生の後悔になるような気がしました。
そして、何事も自分がやりたいと思ったときがベストなタイミングだと思っているので、思い切って今年ワーホリに行くことを決めました。

決意してからはあっという間で、およそ４カ月後の2019年3月には、無事にドイツでのワーホリ生活をスタートしました。今は、新しい生活にわくわくする気持ちでいっぱいです!

## そもそも、なんでドイツなの？

では、なぜ渡航先としてドイツを選んだのか? 実際、「なんでドイツ??」と聞かれることもありましたが、わたしの中では「海外で生活をするなら憧れのヨーロッパ!」と決めていたので、初めにイギリス、ドイツ、フランスを渡航先として選択肢に挙げていました。

![](images/s03.jpg "ドイツといえば……ビール!")

その中からドイツを選択したのは、大学生の頃、ドイツ旅行をしたことがきっかけでした。慣れない海外旅行でしたが、ドイツの人の温かさに触れ、メルヘンチックでかわいらしい街並みに心を奪われ、いつでもおいしいビールを飲める幸せを感じ、ぜひもう一度訪れたいと強く思っていました。そして、ワーキングホリデーに行こうと決めたとき、当時知ったドイツの魅力を思い出し、今回の渡航先に選びました。

![](images/s04.jpg "大好きなドイツの街並み")

そんなこんなで、旅行経験はあるものの、ドイツ語はまったく話せない私は、ほぼ勢いにまかせる形でドイツへの渡航を決めました。渡航までの間、インターネットなどで情報収集をしましたが、ドイツはワーキングホリデーの選択肢として少しマイナーなのか、オーストラリアやカナダなどに比べると得られる情報が限られていたように感じました。そこで、本連載で、今後ドイツに興味を持った方々にとって、ドイツでのワーキングホリデーを人生の選択肢の一つにしていただけるような、少しでも役立つ情報を発信していければ幸いです！

次回のテーマは[「ワーホリ前に必要な準備って？」](/series/workingholiday/02)です。お楽しみに!

[【連載】アラサー女子のワーホリ生活 in ドイツ 一覧へ](/series/workingholiday)`}
                      value={this.props.formObject.markdown}
                      onChange={this.setFormObjectHandler.bind(this)}/>
            </div>
          </div>

        </div>
      </form>
    )
  }
}

export default SupportForm