import React from "react"
import Layout from "../../components/layout"
import styled from "styled-components"
import AdminHeader from "../../components/admin/header"
import jsYaml from "js-yaml"
import Form from "../../components/admin/supportform"
import Markdown from "../../components/admin/markdown"

const Container = styled.div`
  padding: 12px;
  .header {
    h2 {
      float: left;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  pre {
    border: 1px solid black;
    width: 100%;
    height: 600px;
    white-space: pre-wrap;
  }
  .loaderContainer {
    float: right;
    text-align: right;
    select, button {
      margin: 4px;
    }
    select {
      border-radius: 3px;
      border: 1px solid #ccc;
      width: 600px;
      padding: 0.2em 0.5em;
      &::placeholder {
        color: #aaa;
        font-style: italic;
      }
      font-family: monospace;
    }
  }
  .content {
    clear: both;
    display: flex;
    font-size: 90%;
    margin-top: 0.5rem;
    .form-group {
      display: flex;
      margin-bottom: 1rem;
      > label {
        width: 200px;
        margin-top: 5px;
        font-weight: bold;
      }
      &.required > label:after {
        content: '必須';
        color: red;
        font-family: FontAwesome;
        font-size: 60%;
        margin-left: 4px;
      }
      .input-group {
        width: 100%;
      }
      .help-box {
        font-size: 80%;
        margin: 0.2em;
        color: #999;
        line-height: 1.2em;
      }
      input[type="text"], textarea {
        border-radius: 3px;
        border: 1px solid #ccc;
        width: 100%;
        padding: 0.2em 0.5em;
        &::placeholder {
          color: #aaa;
          font-style: italic;
        }
        font-family: monospace;
      }
    }
    .action {
      display: flex;
      flex-direction: column;
      margin-top: 150px;
      > button {
        padding: 1em;
        margin: 1em;
      }
    }
    .form {
      flex 1 1 40%;
    }
    .markdown {
      flex 1 1 40%;
    }
    .collapse {
      display: none;
    }
    .collapse.collapsed {
      display: inherit;
    }
    .collapse-trigger {
      display: block;
      text-align: right;
      color: black;
      font-weight: bold;
      text-decoration: none;
      padding: 0.5em;
      margin-bottom: 1em;
      &::after {
        content: "▽";
        padding-left: 0.5em;
      }
      border-bottom: 1px solid #ccc;
    }
  }
`

class Support extends React.Component {

  constructor (props) {
    super(props)
    this.formDefaultValues = {
      title: '',
      date: '',
      image: '',
      show_top_image: '',
      thumbnail: '',
      lead: '',
      tags: '',
      attributes: '',
      author: '',
      showProfiles: '',
      profile: '',
      avatar: '',
      related: '',
      reserved_at: '',
      series_title: '',
      first: '',
      series_date: '',
      series_path: '',
      markdown: ''
    }
    this.errors = {}
    this.state = {
      formObject: Object.assign({}, this.formDefaultValues),
      formObjectChanged: false,
      markdown: '',
      markdownChanged: false,
      formObjectList: []
    }
  }

  componentDidMount = () => {
    let formObject = localStorage.getItem('supportFormObject')
    let formObjectChanged = false
    let markdownChanged = false
    if (formObject) {
      formObject = Object.assign({}, this.formDefaultValues, JSON.parse(formObject))
      formObjectChanged = true
    } else {
      formObject = Object.assign({}, this.formDefaultValues)
    }
    let markdown = localStorage.getItem('supportMarkdown') || ""
    if (markdown) {
      markdownChanged = true
    }
    this.setState({
      formObject: formObject,
      markdown: markdown,
      formObjectList: this.getFormObjectList(),
      formObjectChanged: formObjectChanged,
      markdownChanged: markdownChanged
    })
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', this.componentWillUnmount)
    }
  };

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('beforeunload', this.componentWillUnmount)
    }
    localStorage.setItem('supportFormObject', JSON.stringify(this.state.formObject))
    localStorage.setItem('supportMarkdown', this.state.markdown)
  }

  convertToForm() {
    if (this.state.formObjectChanged && !confirm('上書きします。よろしいですか？')) {
      return
    }
    try {
      let formObject = Object.assign({}, this.formDefaultValues)
      if (this.state.markdown.match(/^---\n([\s\S]*)---\n([\s\S]*)$/g)) {
        let yaml = RegExp.$1
        let markdown = RegExp.$2
        formObject = jsYaml.safeLoad(yaml, {
          schema: jsYaml.JSON_SCHEMA
        })
        for (let key in formObject) {
          if (['tags', 'related', 'attributes'].includes(key)) {
            formObject[key] = formObject[key].join(',')
          }
        }

        formObject = Object.assign({}, this.formDefaultValues, formObject)
        formObject.markdown = markdown
      }
      this.setState({formObject: formObject})
    } catch (e) {
      alert(e)
    }
  }

  isValid() {
    const rules = {
      title: {
        label: 'タイトル',
        required: true
      },
      date: {
        label: '公開日時',
        required: true,
        regexp: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
      },
      image: {
        label: 'メイン画像',
        required: true
      },
      reserved_at: {
        label: '予約投稿日時',
        regexp: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
      },
      series_date: {
        label: '連載一覧の日時',
        regexp: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
      }

    }
    this.errors = {}
    let result = true
    for (let key in rules) {
      let value = this.state.formObject[key]
      let rule = rules[key]
      if (rule.required) {
        if (!value) {
          this.addError(key, `${rule.label}を入力してください`)
          result = false
          continue
        }
      }
      if (value) {
        if (rule.regexp) {
          if (!value.match(rule.regexp)) {
            this.addError(key, `${rule.label}を正しく入力してください`)
            result = false
          }
        }
      }
    }
    return result
  }

  addError(key, error) {
    if (!this.errors[key]) this.errors[key] = []
    this.errors[key].push(error)
  }

  showErrors() {
    let message = ''
    for (let key in this.errors) {
      for (let error of this.errors[key]) {
        message += error+"\n"
      }
    }
    alert(message)
  }

  convertToMarkdown() {
    if (!this.isValid()) {
      this.showErrors()
      return
    }
    if (this.state.markdownChanged && !confirm('上書きします。よろしいですか？')) {
      return
    }
    try {
      let filteredObject = {}
      let yaml = ''
      let markdown = this.state.formObject.markdown
      for (const key in this.state.formObject) {
        if (key === 'markdown') {
          continue
        }
        if (this.state.formObject.hasOwnProperty(key)) {
          let value = this.state.formObject[key]
          if (value !== '') {
            if (['tags', 'related', 'attributes'].includes(key)) {
              value = value.split(',')
            }
            filteredObject[key] = value
          }
        }
      }
      if (Object.keys(filteredObject).length > 0) {
        yaml = jsYaml.safeDump(filteredObject).trim()
      }
      let content = `---
${yaml}
---
${markdown}`
      this.setState({markdown: content})
    } catch (e) {
      alert(e)
    }
  }

  setMarkdownHandler(markdown) {
    this.setState({markdown: markdown, markdownChanged: true})
  }

  setFormObjectHandler(formObject) {
    this.setState({formObject: formObject, formObjectChanged: true})
  }

  getFormObjectList() {
    let formObjectList = localStorage.getItem('supportFormObjectList')
    if (formObjectList) {
      formObjectList = JSON.parse(formObjectList)
    } else {
      formObjectList = []
    }
    return formObjectList
  }

  saveHandler(e) {
    let selector = document.querySelector(e.target.dataset.selector)
    if (!this.isValid()) {
      this.showErrors()
      return
    }
    let formObjectList = this.state.formObjectList
    formObjectList.unshift(this.state.formObject)
    localStorage.setItem('supportFormObjectList', JSON.stringify(formObjectList))
    this.setState({formObjectList: formObjectList})
    selector.selectedIndex = 0
    alert(`${this.state.formObject.title}をセーブしました`)
  }

  loadHandler(e) {
    let selector = document.querySelector(e.target.dataset.selector)
    if (selector.value !== '') {
      if (this.state.formObjectChanged && !confirm('上書きします。よろしいですか？')) {
        return
      }
      let formObject = Object.assign({}, this.formDefaultValues, this.state.formObjectList[selector.value])
      this.setState({formObject: formObject, formObjectChanged: true})
      selector.selectedIndex = 0
      alert(`${formObject.title}をロードしました`)
    }
  }

  deleteHandler(e) {
    let selector = document.querySelector(e.target.dataset.selector)
    if (selector.value !== '') {
      if (!confirm('削除します。よろしいですか？')) {
        return
      }
      let formObjectList = this.state.formObjectList
      formObjectList.splice(selector.value, 1)
      alert(`${selector.options[selector.selectedIndex].text}を削除しました`)
      selector.selectedIndex = 0
      this.setState({formObjectList: formObjectList})
    }
  }

  clearAll(e) {
    if ((this.state.markdownChanged || this.state.formObjectChanged) && !confirm('編集内容をすべて破棄します。よろしいですか？')) {
      return
    }

    this.setState({
      formObject: Object.assign({}, this.formDefaultValues),
      markdown: '',
      formObjectChanged: false,
      markdownChanged: false
    })
  }

  render () {
    return (
      <Layout maxWidth="100%">
        <Container>
          <AdminHeader/>
          <div className="header">
            <h2>
              入力支援
            </h2>
            <div className="loaderContainer">
              <select id="selector">
                <option/>
                {(() => {
                  return this.state.formObjectList.map((item, index) => {
                    return <option key={index} value={index}>{item.title}</option>
                  })
                })()}
              </select>
              <button onClick={this.loadHandler.bind(this)}
                      data-selector="#selector">load</button>
              <button onClick={this.deleteHandler.bind(this)}
                      data-selector="#selector">delete</button>
              |
              <button onClick={this.saveHandler.bind(this)}
                      data-selector="#selector">save</button>
            </div>
            <div className="content">
              <Form
                setFormObject={this.setFormObjectHandler.bind(this)}
                formObject={this.state.formObject}
              />
              <div className="action">
                <button onClick={this.convertToForm.bind(this)}>
                  <span className="fa fa-caret-left fa-2x"/>
                </button>
                <button onClick={this.convertToMarkdown.bind(this)} type="submit" form="form">
                  <span className="fa fa-caret-right fa-2x"/>
                </button>
                <button onClick={this.clearAll.bind(this)}>クリア</button>
              </div>
              <Markdown
                setMarkdown={this.setMarkdownHandler.bind(this)}
                markdown={this.state.markdown}
              />
            </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default ({ data }) => {
  return (
    <Support/>
  )
}