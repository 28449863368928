import React from "react"
import HelpFile from "raw-loader!../../pages/markdown.txt"

class Markdown extends React.Component {

  setMarkdownHandler(e) {
    this.props.setMarkdown(e.target.value)
  }

  toggleCollapse(e) {
    e.preventDefault()
    let target = document.querySelector('[data-collapse-target='+e.target.dataset.target+']')
    if (target.classList.contains('collapsed')) {
      target.classList.remove('collapsed')
    } else {
      target.classList.add('collapsed')
    }
  }

  render() {
    return (
      <div className="markdown">
        <a href="#" className="collapse-trigger" onClick={this.toggleCollapse.bind(this)} data-target="help">ヘルプを表示</a>
        <pre className="collapse" data-collapse-target="help">{HelpFile}</pre>
        <div className="form-group">
            <textarea id="markdown" rows="25" placeholder={`---
title: |
会社員のためのお医者さん! 産業医の役割や実践するリフレッシュ方法を教えて!--前編--
date: 2019-04-25 10:00:00
image: images/001.jpg
lead: |
近年、働き方改革の推進に伴い、注目度向上中の「産業医」。そこで、産業医グループこころみを運営している松谷将宏先生に、企業における産業医の役割や先生自身が実践するリフレッシュ方法について聞いてきました。
attributes: [new,first,work,life]
tags: [働き方,ライフ,インタビュー]
showProfiles: yes
profile: 大阪府立高校卒業し、和歌山県立医科大学を卒業。大阪市立大学医学部付属病院にて初期臨床研修後、精神科医として大阪の精神科救急病院に勤務し、精神科専門医・精神保健指定医を取得。その後、都内複数のメンタルクリニック・スリープクリニックに勤務しながら、大阪・東京で産業医活動を幅広くう。平成28年、<a href="http://www.cocoro-me.com" target="_blank">（株）産業医グループこころみ</a>を設立。
avatar: images/006.jpg
---

***

連載第1回目となる今回は、自己紹介も兼ねて「私が仕事を辞めてワーキングホリデーに行くことにした理由」について、ご紹介します。

![](images/s01.jpg "画像はドイツまったく関係ない「マーライオンと私」です。")

## 安定した生活と、海外生活の夢

私は、幼い頃から海外に興味をもっており、高校進学後は３カ月のニュージーランド留学も経験しました。その後も、海外生活への関心や憧れは衰えず、いつか海外で働くことを夢に見ていました。

その夢をかなえるべく、大学の就職活動でも海外の就職先を探してはみたものの、実際に海外就職できるほどの語学力もなく、活かせる資格ももっていなかったため、なかなかうまくいかず、日本で社会人経験を積むことになりました。およそ4年間、日本で会社員として働きましたが、やはり「海外で生活する」という夢を諦めきれず、2018年11月に「やっぱりワーホリ行こう!」と決め、上司に伝えました。

![](images/s02.jpg "学生時代にドイツを旅したときの写真")

もちろん、日本での安定した生活を手放すことは勇気がいりましたが、「仕事を辞めるリスク」と「海外に行きたい気持ち」を天秤にかけたときに、今、ワーホリに行かない選択をすることの方が人生の後悔になるような気がしました。
そして、何事も自分がやりたいと思ったときがベストなタイミングだと思っているので、思い切って今年ワーホリに行くことを決めました。

決意してからはあっという間で、およそ４カ月後の2019年3月には、無事にドイツでのワーホリ生活をスタートしました。今は、新しい生活にわくわくする気持ちでいっぱいです!

## そもそも、なんでドイツなの？

では、なぜ渡航先としてドイツを選んだのか? 実際、「なんでドイツ??」と聞かれることもありましたが、わたしの中では「海外で生活をするなら憧れのヨーロッパ!」と決めていたので、初めにイギリス、ドイツ、フランスを渡航先として選択肢に挙げていました。

![](images/s03.jpg "ドイツといえば……ビール!")

その中からドイツを選択したのは、大学生の頃、ドイツ旅行をしたことがきっかけでした。慣れない海外旅行でしたが、ドイツの人の温かさに触れ、メルヘンチックでかわいらしい街並みに心を奪われ、いつでもおいしいビールを飲める幸せを感じ、ぜひもう一度訪れたいと強く思っていました。そして、ワーキングホリデーに行こうと決めたとき、当時知ったドイツの魅力を思い出し、今回の渡航先に選びました。

![](images/s04.jpg "大好きなドイツの街並み")

そんなこんなで、旅行経験はあるものの、ドイツ語はまったく話せない私は、ほぼ勢いにまかせる形でドイツへの渡航を決めました。渡航までの間、インターネットなどで情報収集をしましたが、ドイツはワーキングホリデーの選択肢として少しマイナーなのか、オーストラリアやカナダなどに比べると得られる情報が限られていたように感じました。そこで、本連載で、今後ドイツに興味を持った方々にとって、ドイツでのワーキングホリデーを人生の選択肢の一つにしていただけるような、少しでも役立つ情報を発信していければ幸いです！

次回のテーマは[「ワーホリ前に必要な準備って？」](/series/workingholiday/02)です。お楽しみに!

[【連載】アラサー女子のワーホリ生活 in ドイツ 一覧へ](/series/workingholiday)`} value={this.props.markdown} onChange={this.setMarkdownHandler.bind(this)}/>
        </div>
      </div>
    )
  }
}

export default Markdown